import React from 'react';
import scholarship1 from "../images/scholarship1.jpeg";
import scholarship2 from "../images/scholarship2.jpeg";
import scholarship3 from "../images/scholarship3.jpeg";
import refugeeSupport from "../images/refugeeSupport.jpeg";
import studentSponsorship from "../images/studentSponsorship.jpeg";
import leadershipDevelopment from "../images/leadershipDevelopment.jpeg";
import entrepreneurialSkill from "../images/entrepreneurialSkill.jpeg";
import policyAdvocacy1 from "../images/policyAdvocacy1.jpeg";
import climateEducation from "../images/climateEducation.jpeg";
import peacebuilding from "../images/peacebuilding.jpeg";
import logo from "../images/logo.png";
import learning from "../images/learning.jpeg";

function Programs() {
    return (
        <>
            <div className="py-10 px-5 bg-black text-white">
                <h1 className="text-4xl font-bold text-center mb-10">Our Programs</h1>

                <section className="mb-10">
                    <h2 className="text-2xl text-red-700 border-b-2 border-red-700 pb-2 mb-5">I. Scholarship Preparatory Training Program (SPTP)</h2>
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 p-5 bg-gray-900 rounded-lg shadow-lg">
                        <img src={learning} alt="Advocacy and Peacebuilding/Nonviolence" className="w-full h-96 object-cover rounded-lg shadow-md" />
                        <p className="text-gray-300 text-base leading-relaxed">The Scholarship Preparatory Training Program (SPTP) is a holistic program designed to equip students with essential skills, knowledge, and resources to compete for scholarships and pursue higher education. Through comprehensive training, including academic enhancement, scholarship orientation sessions, essay writing, and English proficiency preparation, students gain confidence in scholarship applications. Leveraging experiences from our Students who are studying in African, US, and Asian universities, SPTP ensures access to higher education opportunities and we have seen the transformative impacts it has been cultivating.</p>
                    </div>
                </section>

                <section className="mb-10">
                    <h2 className="text-2xl text-red-700 border-b-2 border-red-700 pb-2 mb-5">II. Limited Financial Support for Refugee and IDP Youth</h2>
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 p-5 bg-gray-900 rounded-lg shadow-lg">
                        <p className="text-gray-300 text-base leading-relaxed">Recognizing financial barriers faced by youth in refugee and internally displaced camps, we offer limited financial support covering admission fees, English proficiency test examinations, and obtaining foreign equating certification. Our limited assistance will extend to facilitating internet access for virtual sessions and supporting passport acquisition for refugees & IDP youth, to ensure equal opportunities for underprivileged youth.</p>
                        <img src={refugeeSupport} alt="Limited Financial Support for Refugee and IDP Youth" className="w-full h-96 object-cover rounded-lg shadow-md" />
                    </div>
                </section>

                <section className="mb-10">
                    <h2 className="text-2xl text-red-700 border-b-2 border-red-700 pb-2 mb-5">III. Primary and Secondary Students Sponsorships</h2>
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 p-5 bg-gray-900 rounded-lg shadow-lg">
                        <img src={studentSponsorship} alt="Primary and Secondary Students Sponsorships" className="w-full h-96 object-cover rounded-lg shadow-md" />
                        <p className="text-gray-300 text-base leading-relaxed">In addressing the educational crisis among primary and secondary school-aged children in refugee and IDP camps, in the future, we plan to sponsor their education. With over 2.8 million out-of-school children in South Sudan, our aim is to break the cycle of illiteracy and poverty by providing sponsorship opportunities and preparing them for future scholarship programs. SSYLNET's Education Sponsorship Program will partner with NGOs, schools, and refugee communities in East Africa to sponsor students from primary to secondary levels. It facilitates university transitions, offers mentorship, and engages communities to empower refugee students, fostering education access and long-term empowerment in refugee camps.</p>
                    </div>
                </section>

                <section className="mb-10">
                    <h2 className="text-2xl text-red-700 border-b-2 border-red-700 pb-2 mb-5">B. Leadership Development Program</h2>
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 p-5 bg-gray-900 rounded-lg shadow-lg">
                        <p className="text-gray-300 text-base leading-relaxed">Our leadership development programs focus on nurturing ethical and transformative leaders equipped with the skills to drive positive change. Through workshops, mentorship, career guidance, and experiential learning led by our Professional Mentors and experienced leaders. SSYLNET empowers youth to make meaningful impacts in their communities. Our approach is grounded in reconciliation, education, entrepreneurship, and leadership, fostering inner transformation and impactful leadership.</p>
                        <img src={leadershipDevelopment} alt="Leadership Development Program" className="w-full h-98 object-cover rounded-lg shadow-md" />
                    </div>
                </section>

                <section className="mb-10">
                    <h2 className="text-2xl text-red-700 border-b-2 border-red-700 pb-2 mb-5">C. Entrepreneurial Skill Empowerment</h2>
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 p-5 bg-gray-900 rounded-lg shadow-lg">
                        <img src={entrepreneurialSkill} alt="Entrepreneurial Skill Empowerment" className="w-full h-96 object-cover rounded-lg shadow-md" />
                        <p className="text-gray-300 text-base leading-relaxed">SSYLNET empowers young entrepreneurs to create sustainable solutions for economic development. Through social entrepreneurship initiatives, we provide aspiring business leaders with knowledge, resources, opportunities, and support to address local challenges and foster positive change. Our programs cultivate entrepreneurship skills among marginalized youth, promoting economic empowerment and sustainable livelihoods.</p>
                    </div>
                </section>

                <section className="mb-10">
                    <h2 className="text-2xl text-red-700 border-b-2 border-red-700 pb-2 mb-5">D. Policy Advocacy</h2>
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 p-5 bg-gray-900 rounded-lg shadow-lg">
                        <p className="text-gray-300 text-base leading-relaxed">Our advocacy efforts champion policies and programs prioritizing education, youth empowerment, and peacebuilding in South Sudan. We amplify the voices of underprivileged youth in policy discussions, advocating for systemic change and sustainable development. SSYLNET strives to influence policy decisions for a brighter future in South Sudan.</p>
                        <img src={policyAdvocacy1} alt="Policy Advocacy" className="w-full h-98 object-cover rounded-lg shadow-md" />
                    </div>
                </section>

                <section className="mb-10">
                    <h2 className="text-2xl text-red-700 border-b-2 border-red-700 pb-2 mb-5">E. Climate Education and Environmental Awareness</h2>
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 p-5 bg-gray-900 rounded-lg shadow-lg">
                        <img src={climateEducation} alt="Climate Education and Environmental Awareness" className="w-full h-96 object-cover rounded-lg shadow-md" />
                        <p className="text-gray-300 text-base leading-relaxed">We raise awareness about climate change and empower youth to take action through education and advocacy. By equipping young people with knowledge and tools to address environmental challenges, we foster environmental stewardship and resilience. SSYLNET advocates for solutions to address climate change, aiming to secure a sustainable future for South Sudan.</p>
                    </div>
                </section>

                <section className="mb-10">
                    <h2 className="text-2xl text-red-700 border-b-2 border-red-700 pb-2 mb-5">F. Advocacy and Peacebuilding/Nonviolence</h2>
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 p-5 bg-gray-900 rounded-lg shadow-lg">
                        <p className="text-gray-300 text-base leading-relaxed">We champion policies and programs that prioritize education, youth empowerment, and peacebuilding, aiming to translate underprivileged youth into peacemakers in society. Through sessions, workshops, and advocacy, we promote a culture of non-violence, striving for a just, peaceful, and prosperous South Sudan. Our activities include advocacy, research, capacity building, outreach, and youth engagement.</p>
                        <img src={peacebuilding} alt="Advocacy and Peacebuilding/Nonviolence" className="w-full h-96 object-cover rounded-lg shadow-md" />
                    </div>
                </section>
            </div>
            <footer className="bg-darkred-700 text-white py-10 px-5 flex flex-col md:flex-row justify-around items-center mt-10">
                <div className="mb-5 md:mb-0 text-center md:text-left">
                    <img src={logo} alt="Company Logo" className="w-32 mb-3 mx-auto md:mx-0 rounded bg-white p-1" />
                    <p>Empowering Youth, Building Futures and Trailblazing New Horizons</p>
                </div>
                <div className="mb-5 md:mb-0">
                    <h3 className="text-xl mb-3">Quick Links</h3>
                    <ul>
                        <li className="mb-2"><a href="/who-we-are" className="text-white hover:text-gray-300">About Us</a></li>
                        <li className="mb-2"><a href="/programs" className="text-white hover:text-gray-300">Programs</a></li>
                        <li className="mb-2"><a href="/impact" className="text-white hover:text-gray-300">Resources</a></li>
                        <li className="mb-2"><a href="/media" className="text-white hover:text-gray-300">Galleries</a></li>
                        <li className="mb-2"><a href="/call-for-action" className="text-white hover:text-gray-300">Take Action</a></li>
                        <li className="mb-2"><a href="/contact-us" className="text-white hover:text-gray-300">Contact Us</a></li>
                    </ul>
                </div>
                <div className="mb-5 md:mb-0">
                    <h3 className="text-xl mb-3">Follow Us</h3>
                    <a href="https://www.linkedin.com/company/south-sudan-youth-leadership-scholars-network-program/?viewAsMember=true" className="block mb-2 text-white hover:text-gray-300">LinkedIn</a>
                    <a href="https://www.facebook.com/people/South-Sudan-Youth-Leadership-Scholars-Network-Program-SSYLNET/100092027318021/" className="block mb-2 text-white hover:text-gray-300">Facebook</a>
                    <a href="https://twitter.com/i/flow/login?redirect_after_login=%2Fssylnet" className="block mb-2 text-white hover:text-gray-300">Twitter</a>
                </div>
                <div>
                    <h3 className="text-xl mb-3">Contact Us</h3>
                    <p>P.O. Box 29, Juba, South Sudan, Central Equatoria State, Internally Displaced Camp_Poc3</p>
                    <p>+211 929 415 361, +250 792 577 523</p>
                    <p>southsudanyouthnetwork.scholars<br />@gmail.com</p>
                </div>
            </footer>
            <p className="text-white text-center py-4">©️ SSYLNET Organization 2024 | All rights reserved: Website Designed by <span style={{ color: "darkred" }}>Joshua Alana</span></p>
        </>
    );
}

export default Programs;
