import React, { useState } from 'react';
import logo from '../images/logo.png';
import coach1 from '../images/coach1.jpeg';
import coach2 from '../images/coach2.jpeg';
import coach3 from '../images/coach3.jpeg';
import coach4 from '../images/coach4.jpeg';
import coach5 from '../images/coach5.jpeg';
import '../styles/mentorsAndCoaches.css';

const MentorsAndCoaches = () => {
    const [showMore, setShowMore] = useState({
        member1: false,
        member2: false,
        member3: false,
        member4: false,
        member5: false,
    });

    const handleReadMore = (member) => {
        setShowMore((prevState) => ({ ...prevState, [member]: !prevState[member] }));
    };

    const mentors = [
        {
            src: coach1,
            name: "Damien Mouzoun",
            title: "Founder & Chief Meaning Officer, Ayina Think Tank",
            bio: "Damien Mouzoun is a Social Entrepreneur from Benin, currently residing in Rwanda who was named in 2017 among the 100 most influential young Africans for his advocacy work across the entire continent.",
            id: "member1",
            social: [
                { href: "https://www.linkedin.com/in/damien-mouzoun-60443435/", text: "LinkedIn" }
            ],
            additionalBio: "He graduated in 2014 with a Master’s degree in Social Innovation Management from the Amani Institute in Kenya. Damien is the Founder & Chief Meaning Officer of Ayina Think Tank, a modern agora for lifelong learners in search of information, knowledge, and wisdom for leadership development, intuitive learning, and conservation. The institution’s logo features a fractional triangle and circle in one, symbolizing its holistic approach. He is an active member of several esteemed organizations, including Rotary International, ASHOKA, Junior Chamber International, Civil Society Academy, World Wildlife Fund, The Church of Jesus Christ of Latter-day Saints, and the professional networks of Stephen R. Covey and Clayton M. Christensen from Harvard Business School. Damien’s defining moment came during his exile in Ghana, where he advocated for quality education, government accountability, and justice in Benin. He firmly believes in the power of immediate action and the ability to learn, unlearn, and relearn throughout one’s journey. While serving as an advisor for the Young African Leaders Initiative (YALI), later named after Nelson Mandela by President Barack Obama’s administration, Damien identified the need for a global research and advocacy lab focused on servant leadership, progressivism, and environmental protection in Africa. This led to the establishment of Ayina Think Tank, which now has offices in Rwanda, Ghana, Mauritius, Senegal, Switzerland, and Japan. The think tank empowers young people to voice their opinions, innovate, and become the change-making leaders they aspire to be. Damien consults with public and private institutions across various sectors, including politics, business, and humanitarian organizations worldwide."
        },
        {
            src: coach2,
            name: "Eng. Bior Atem Bior Barach",
            title: "Certified Neuroencoding Specialist & Sabotage Assassinator",
            bio: "Eng. Bior Atem Bior Barach: graduated from the University Technology of Malaysia (UTM) with a Master of Engineering in Petroleum Engineering and from PETRONAS University of Technology (UTP) with a Bachelor of Engineering in Petroleum.",
            id: "member2",
            social: [
                { href: "https://www.linkedin.com/in/bior-atem-bior-barach-27003783/", text: "LinkedIn" }
            ],
            additionalBio: "Bior's career in engineering began in 2013 when he started working for the Ministry of Petroleum in the Republic of South Sudan after returning from studying abroad in Malaysia. In 2014, he joined PETRONAS as a Petrophysicist and then moved to Malaysia, stationed at PETRONAS Twin Towers in Kuala Lumpur. His expertise includes logging operations and evaluations (in both open and cased hole conditions). He is particularly passionate about Petrophysical Rock Typing approaches, Saturation Height Functions, and has worked in multidisciplinary teams to mature Field Development Plans (FDPs), Full Field Reviews (FFRs), and Hydrocarbon resource and reserves assessment exercises. Outside of his engineering career, Bior is dedicated to personal development as a Mindset Coach, Speaker, and Mentor. Being a certified Neurorecording Specialist, he uses his skills to help young professionals maximize their potential and how to get the best out of themselves. His courage and determination have inspired followers from his home country and worldwide. He invests much of his time in assisting students, fresh graduates, and young professionals to overcome negative self-talk, self-sabotage, and procrastination, helping them discover their untapped talents and infinite potential. He hones his uniquely branded teaching and coaching style of 'Tell, Show, Do,' to create massive personal transformation that effectively moves people to take on actions more consistently and go 'Further Faster' with their personal and business achievements. His commitment to mentorship and personal growth has made a significant impact on many aspiring professionals."
        },
        {
            src: coach3,
            name: "Eng. Thon Malek Garang",
            title: "Co-founder Dimmunity Computing Academy",
            bio: "Thon Malek Garang is a highly accomplished computer science professional with a diverse academic background, holding an MSc in Engineering Artificial Intelligence from Carnegie Mellon University, an MSc in Mobile Application Development (EMoS) from the Nelson Mandela African Institution of Science & Technology, and a BSc in Software Engineering from the University of Eastern Africa, Baraton.",
            id: "member3",
            social: [
                { href: "https://twitter.com/ok_thon", text: "Twitter" }
            ],
            additionalBio: "He boasts a rich portfolio of certifications, including CISA, CISM, CRISC, and PMP. Currently serving as lecturer and the head of the Computer Science Department at Upper Nile University. Thon is deeply involved in software development consultancies besides being a co-founder of both Dimmunity Computing Academy and World Innovation, Research, and Education. He gained international recognition in 2021 and 2023 by winning the prestigious National Geographic Society awards for his outstanding contributions to the application of ICT in wildlife conservation. Throughout his career, Thon has been instrumental in developing and supervising various software development projects for charitable institutions, corporate entities, and government institutions in South Sudan and the wider region. Beyond his professional accomplishments, he serves as a personal coach and motivator while actively promoting STEM education—an agent of positive change in his community and beyond. Thon uses NLP to enhance information access in Africa through innovative projects like developing local voice assistants in African languages and effectively processing hate speech within the context of micro-aggressive statements, with the ultimate goal of fostering linguistic diversity, cultural inclusivity, and responsible technology use on the continent."
        },
        {
            src: coach4,
            name: "Bell-Merci YAMINDII",
            title: "Founder & CEO, Commercial-Community-Incubateur Africa CCI-Africa",
            bio: "Bell-Merci YAMINDII is a Central African national with an MSc in Leadership & Entrepreneurship. He is an academic member and honorary teacher at the Pontifical Catholic University of America (PCUA). As a scholarship recipient of the YALI (Young African Leaders Initiatives) program, he pursued the Business & Entrepreneurship branch at CESAG University (YALI-DAKAR).",
            id: "member4",
            social: [
                { href: "https://www.linkedin.com/in/bell-merci-yamindi-097898152/", text: "LinkedIn" }
            ],
            additionalBio: "YAMINDII serves as a Mentor and Coach in Entrepreneurship at the University of Bangui, contributing to the Technological Entrepreneurship Accelerator project. He founded an incubator, Commercial-Community-Incubateur Africa, which supports entrepreneurs across Africa, and leads the Conseil National des Jeunes Centrafricains Entrepreneurs (CNJECA), an initiative he spearheaded. He is a coach and mentor for youth, providing consultancy in leadership, entrepreneurship, business, and related fields. He is also an ICESCO Ambassador for Peace and Security, appointed by the Islamic World Educational, Scientific and Cultural Organization after completing a leadership training and incubation program in Tangier, Morocco, in 2022. With seven years of experience in various sectors, YAMINDII is the National Contact Point for the Space Generation Advisory Council (SGAC) and Coordinator of the Association of Young Champions of the Fight against Malaria in the Central African Republic. Additionally, he is the Ambassador of the New Horizons edition (US Department of State) in the Central African Republic, the Ambassador of the Next Einstein Forum program in the Central African Republic, and the Ambassador of Zindi in the Central African Republic."
        },
        {
            src: coach5,
            name: "Sammy Edwards",
            title: "Director of Girls Lacrosse for Santa Monica Vikings and Alliance LA",
            bio: "Sammy Edwards is a United States national and a lacrosse coach and personal mentor based in Los Angeles, known for guiding players and individuals towards profound personal and athletic transformations. With over a decade of coaching experience in his local community, Sammy has established himself as a prominent figure in the field.",
            id: "member5",
            social: [
                { href: "https://www.linkedin.com/in/sammy-edwards-691322117/", text: "LinkedIn" }
            ],
            additionalBio: "Currently, Sammy serves as the Director of Girls Lacrosse for Santa Monica Vikings and Alliance LA, and also coaches at Loyola High School. His extensive experience and dedication have made him a respected mentor in the lacrosse community. As a personal development coach, Sammy adopts a holistic approach that nurtures vocal and visual image, alongside mentality, aiding people of all ages in realizing and achieving their ambitions. His commitment to personal and athletic development has positively impacted countless individuals, helping them to reach their full potential both on and off the field."
        }
    ];

    return (
        <>
            <div className="bg-darkred text-white text-center py-10 px-5 rounded-lg mb-5">
                <h1 className="text-3xl font-bold mb-5">Our Professional Mentors & Coaches</h1>
                <p className="text-lg">
                    Our dedicated professional mentors, coaches, and master guide leaders train and coach young people to become leaders and catalysts of change. They focus on nurturing ethical and transformative leaders equipped with the skills to drive positive change. This includes leadership training, career guidance, mentorship, entrepreneurial skill training, and capacity building to empower the SSYLNET staff team, enabling them to make a significant impact in their communities.
                </p>
            </div>
            <div className="flex flex-wrap justify-center gap-5 px-5">
                {mentors.map((member, index) => (
                    <div key={index} className="bg-white text-black rounded-lg shadow-md p-5 w-80">
                        <img src={member.src} alt={member.name} className="rounded-full w-32 h-32 mx-auto mb-4" />
                        <div className="text-left">
                            <h2 className="text-xl font-bold mb-2 text-center">{member.name}</h2>
                            <h4 className="text-lg text-red-700 mb-4 text-center">{member.title}</h4>
                            <p className="text-sm mb-4">
                                {member.bio}
                                {showMore[member.id] && <span className="block mt-2">{member.additionalBio}</span>}
                            </p>
                            <button
                                onClick={() => handleReadMore(member.id)}
                                className="bg-red-700 text-white py-2 px-4 rounded mt-2 transition-colors duration-300 hover:bg-red-600"
                            >
                                {showMore[member.id] ? 'Read Less' : 'Read More'}
                            </button>
                            <div className="mt-4 space-x-2 text-center">
                                {member.social.map((link, idx) => (
                                    <a key={idx} href={link.href} target="_blank" rel="noopener noreferrer" className="text-red-700 hover:text-red-900">{link.text}</a>
                                ))}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <footer className="bg-darkred-700 text-white py-10 px-5 flex flex-col md:flex-row justify-around items-center mt-10">
                <div className="mb-5 md:mb-0 text-center md:text-left">
                    <img src={logo} alt="Company Logo" className="w-32 mb-3 mx-auto md:mx-0 rounded bg-white p-1" />
                    <p>Empowering Youth, Building Futures and Trailblazing New Horizons</p>
                </div>
                <div className="mb-5 md:mb-0">
                    <h3 className="text-xl mb-3">Quick Links</h3>
                    <ul>
                        <li className="mb-2"><a href="/who-we-are" className="text-white hover:text-gray-300">About Us</a></li>
                        <li className="mb-2"><a href="/programs" className="text-white hover:text-gray-300">Programs</a></li>
                        <li className="mb-2"><a href="/impact" className="text-white hover:text-gray-300">Resources</a></li>
                        <li className="mb-2"><a href="/media" className="text-white hover:text-gray-300">Galleries</a></li>
                        <li className="mb-2"><a href="/call-for-action" className="text-white hover:text-gray-300">Take Action</a></li>
                        <li className="mb-2"><a href="/contact-us" className="text-white hover:text-gray-300">Contact Us</a></li>
                    </ul>
                </div>
                <div className="mb-5 md:mb-0">
                    <h3 className="text-xl mb-3">Follow Us</h3>
                    <a href="https://www.linkedin.com/company/south-sudan-youth-leadership-scholars-network-program/?viewAsMember=true" className="block mb-2 text-white hover:text-gray-300">LinkedIn</a>
                    <a href="https://www.facebook.com/people/South-Sudan-Youth-Leadership-Scholars-Network-Program-SSYLNET/100092027318021/" className="block mb-2 text-white hover:text-gray-300">Facebook</a>
                    <a href="https://twitter.com/i/flow/login?redirect_after_login=%2Fssylnet" className="block mb-2 text-white hover:text-gray-300">Twitter</a>
                </div>
                <div>
                    <h3 className="text-xl mb-3">Contact Us</h3>
                    <p>P.O. Box 29, Juba, South Sudan, Central Equatoria State, Internally Displaced Camp_Poc3</p>
                    <p>+211 929 415 361, +250 792 577 523</p>
                    <p>southsudanyouthnetwork.scholars<br />@gmail.com</p>
                </div>
            </footer>
            <p className="text-white text-center py-4">©️ SSYLNET Organization 2024 | All rights reserved: Website Designed by <span style={{ color: "darkred" }}>Joshua Alana</span></p>
        </>
    );
};

export default MentorsAndCoaches;
