import React from 'react';
import logo from "../images/logo.png";

function OurValue() {
    return (
        <>
            <div className="max-w-7xl mx-auto p-5 flex flex-col gap-5 bg-black rounded-lg shadow-lg text-center items-center">
                <h1 className="text-center text-red-700 my-5 text-4xl font-bold">Our Core Values</h1>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 w-full">
                    <div className="bg-white p-5 rounded-lg shadow-md hover:shadow-lg transition-transform transform hover:-translate-y-1">
                        <h1 className="text-2xl text-red-700 mb-3">Diversity and Inclusiveness</h1>
                        <p className="text-lg text-black">We value and promote the equal participation and representation of all young people, regardless of their background or identity.</p>
                    </div>
                    <div className="bg-white p-5 rounded-lg shadow-md hover:shadow-lg transition-transform transform hover:-translate-y-1">
                        <h1 className="text-2xl text-red-700 mb-3">Integrity</h1>
                        <p className="text-lg text-black">We uphold honesty and ethical behavior in all our actions and decisions, being accountable to our stakeholders.</p>
                    </div>
                    <div className="bg-white p-5 rounded-lg shadow-md hover:shadow-lg transition-transform transform hover:-translate-y-1">
                        <h1 className="text-2xl text-red-700 mb-3">Transformation</h1>
                        <p className="text-lg text-black">We believe in the power of positive influence and dedicated efforts to uplift individuals and communities toward meaningful change.</p>
                    </div>
                    <div className="bg-white p-5 rounded-lg shadow-md hover:shadow-lg transition-transform transform hover:-translate-y-1">
                        <h1 className="text-2xl text-red-700 mb-3">Respect</h1>
                        <p className="text-lg text-black">We treat everyone with dignity and fairness, fostering a culture of tolerance and acceptance.</p>
                    </div>
                    <div className="bg-white p-5 rounded-lg shadow-md hover:shadow-lg transition-transform transform hover:-translate-y-1">
                        <h1 className="text-2xl text-red-700 mb-3">Servant-hood Leadership</h1>
                        <p className="text-lg text-black">We emphasize leadership that prioritizes serving others, inspired by the example of selflessness.</p>
                    </div>
                    <div className="bg-white p-5 rounded-lg shadow-md hover:shadow-lg transition-transform transform hover:-translate-y-1">
                        <h1 className="text-2xl text-red-700 mb-3">Empowerment</h1>
                        <p className="text-lg text-black">We believe in providing education, skills development, and opportunities to empower young people to contribute positively to society.</p>
                    </div>
                </div>
            </div>
            <footer className="bg-darkred-700 text-white p-10 flex flex-col md:flex-row justify-around items-center mt-10">
                <div className="mb-5 md:mb-0 text-center md:text-left">
                    <img src={logo} alt="Company Logo" className="w-32 mb-3 mx-auto md:mx-0 rounded bg-white p-1" />
                    <p>Empowering Youth, Building Futures and Trailblazing New Horizons</p>
                </div>
                <div className="mb-5 md:mb-0">
                    <h3 className="text-xl mb-3">Quick Links</h3>
                    <ul>
                        <li className="mb-2"><a href="/who-we-are" className="text-white hover:text-gray-300">About Us</a></li>
                        <li className="mb-2"><a href="/programs" className="text-white hover:text-gray-300">Programs</a></li>
                        <li className="mb-2"><a href="/impact" className="text-white hover:text-gray-300">Resources</a></li>
                        <li className="mb-2"><a href="/media" className="text-white hover:text-gray-300">Galleries</a></li>
                        <li className="mb-2"><a href="/call-for-action" className="text-white hover:text-gray-300">Take Action</a></li>
                        <li className="mb-2"><a href="/contact-us" className="text-white hover:text-gray-300">Contact Us</a></li>
                    </ul>
                </div>
                <div className="mb-5 md:mb-0">
                    <h3 className="text-xl mb-3">Follow Us</h3>
                    <a href="https://www.linkedin.com/company/south-sudan-youth-leadership-scholars-network-program/?viewAsMember=true" className="block mb-2 text-white hover:text-gray-300">LinkedIn</a>
                    <a href="https://www.facebook.com/people/South-Sudan-Youth-Leadership-Scholars-Network-Program-SSYLNET/100092027318021/" className="block mb-2 text-white hover:text-gray-300">Facebook</a>
                    <a href="https://twitter.com/i/flow/login?redirect_after_login=%2Fssylnet" className="block mb-2 text-white hover:text-gray-300">Twitter</a>
                </div>
                <div>
                    <h3 className="text-xl mb-3">Contact Us</h3>
                    <p>P.O. Box 29, Juba, South Sudan, Central Equatoria State, Internally Displaced Camp_Poc3</p>
                    <p>+211 929 415 361, +250 792 577 523</p>
                    <p>southsudanyouthnetwork.scholars<br />@gmail.com</p>
                </div>
            </footer>
            <p className="text-white text-center py-4">©️ SSYLNET Organization 2024 | All rights reserved: Website Designed by <span style={{ color: "darkred" }}>Joshua Alana</span></p>
        </>
    );
}

export default OurValue;
