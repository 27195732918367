import React, { useState } from 'react';
import '../App.css';
import mapImage from '../images/map.jpeg';
import featured1 from '../images/featured1.jpg';
import featured2 from '../images/featured2.jpg';
import education from '../images/education.jpeg';
import mandela from '../images/mandela.jpeg';
import featured5 from '../images/featured5.jpg';
import logo from '../images/logo.png';

function Home() {
    const [expanded, setExpanded] = useState(false);

    const handleReadMore = () => {
        setExpanded(!expanded);
    };

    return (
        <>
            <div className="relative w-full h-screen overflow-hidden bg-black">
                <div className="absolute inset-0 bg-cover bg-center hidden md:block flipImages"></div>
                <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-left text-white bg-black bg-opacity-55 p-4 sm:p-6 md:p-10 rounded-lg shadow-md z-10 max-w-xl w-11/12">
                    <h1 className="text-xl sm:text-2xl sm:text-left sm:p- md:text-4xl font-bold tracking-wide text-red-700 mb-5">Empowering Youth, Building Futures and Trailblazing New Horizons</h1>
                    <p className="text-sm sm:text-base md:text-lg font-bold leading-loose">
                        We are the dream architects. <br /><br />
                        Through our transformative programs, we empower & support underprivileged youth, build futures, and accelerate Sustainable Development Goal (SDG-4) to end the highest illiteracy and education inequality in South Sudan. <br /><br />
                        We sculpt ethical entrepreneurial leaders, turning dreams into the vibrant reality of a brighter South Sudan.
                    </p>
                </div>
            </div>

            <div className="feature-section grid md:grid-cols-2 gap-10 p-5 bg-black rounded-lg shadow-md mt-5">
                <img src={mapImage} alt="Map of South Sudan" className="w-full h-60 md:h-80 object-cover rounded-lg shadow-md" />
                <div className="flex flex-col justify-center">
                    <h2 className="text-2xl md:text-3xl text-red-700 mb-3">About South Sudan</h2>
                    <p className="text-white text-lg mb-3">The Republic of South Sudan is the youngest nation in the world, born out of struggle and resilience. With a population of 11.18 million (2024 report) and gained independence on July 9th, 2011, following decades of civil war that claimed the lives of two million people and displaced four million others.</p>
                    {expanded && (
                        <div className="transition-all overflow-hidden">
                            <p className="text-white text-lg mb-3">
                                <span className="text-red-700 text-lg">Reinstating Turmoil: The Era of Civil Political Warfare</span>
                                <br /> Tragically, just two years after independence, South Sudan descended into another cycle of civil conflict. The aftermath of these conflicts resulted in another 400,000+ deaths and left 2.3 million internally displaced people in IDP camps and 2.4 million refugees seeking safety in neighboring countries.
                                <br /><br />The scars of war run deep, with poverty gripping our nation. Eight out of ten South Sudanese live in poverty, surviving on less than $1.90 a day. Amidst these challenges, access to education remains a distant dream for many, with over 70% of our population facing illiteracy.
                                <br /><br /><span className="text-red-700 text-lg">Championing Education for Change</span><br />
                                Nelson Mandela once said, "Education is the most powerful weapon which you can use to change the world." At SSYLNET, we believe in the transformative power of education to break the cycle of poverty and conflict.
                            </p>
                        </div>
                    )}
                    <button onClick={handleReadMore} className="bg-red-700 text-white py-2 px-4 rounded mt-4 transition-colors duration-300 hover:bg-red-600">
                        {expanded ? "Read Less" : "Read More"}
                    </button>
                </div>
            </div>

            {[
                { img: mandela, title: "Why Does Education (SDG-4) Matter?", text: "Education is not just a privilege; it's a fundamental human right. As Nelson Mandela wisely stated, 'Education is the most powerful weapon which you can use to change the world.' Beyond imparting knowledge, education empowers individuals, providing them with the tools to overcome obstacles, fulfill aspirations, and uplift communities. It serves as the bedrock of progress, offering pathways to opportunity while fostering personal growth and understanding." },
                { img: featured2, title: "Quality Education: Unlocking Potential", text: "Quality education is essential for both youth and child's holistic development, personal empowerment, and future prospects. It acts as the key to unlocking each youth and child's unique potential, nurturing creativity, talent, and imagination. By fostering collaboration, critical thinking, and problem-solving skills, education cultivates confident, well-rounded individuals capable of contributing meaningfully to society. Moreover, it broadens youth and children's perspectives, igniting curiosity and paving the way for endless possibilities." },
                { img: education, title: "Education: A Catalyst for Change", text: "Education holds the power to break the cycle of poverty, conflicts, and injustice, transforming lives and communities. It serves as a catalyst for empowerment, employment, and improved health outcomes. By promoting equity and driving economic growth, education lays the foundation for peaceful, inclusive societies. Indeed, it is the cornerstone of vibrant communities, thriving societies, and prosperous nations." },
                { img: featured1, title: "Our Commitment to Education", text: "At SSYLNET, we recognize the transformative potential of education. That's why we invest in educational initiatives and partner with local school communities to enhance their educational ecosystems. By equipping students with the tools and resources they need to succeed, we are investing in a brighter future for generations to come. Join us in our commitment to education, as we work towards building South Sudan where every child and youth has the opportunity to thrive and succeed." },
                { img: featured5, title: "Breaking Stereotypes", text: "In a country torn by conflict, education becomes a catalyst for understanding and tolerance. By challenging stereotypes and fostering critical thinking, we build bridges for harmonious coexistence. With over 70% of our population above the age of 15 being illiterate, education is essential for nurturing democratic values. It empowers individuals to discern truth from opinion, fostering informed decision-making and active participation in civic life." },
            ].map((feature, index) => (
                <div key={index} className="feature-section grid md:grid-cols-2 gap-10 p-5 bg-black rounded-lg shadow-md mt-5">
                    <img src={feature.img} alt="Feature" className="w-full h-60 md:h-80 object-cover rounded-lg shadow-md" />
                    <div className="flex flex-col justify-center">
                        <h3 className="text-red-700 text-2xl font-bold mb-3">{feature.title}</h3>
                        <p className="text-white text-lg">{feature.text}</p>
                    </div>
                </div>
            ))}

            <footer className="bg-darkred-700 text-white py-10 px-5 flex flex-col md:flex-row justify-around items-center">
                <div className="mb-5 md:mb-0 text-center md:text-left">
                    <img src={logo} alt="Company Logo" className="w-32 mb-3 mx-auto md:mx-0 rounded bg-white p-1" />
                    <p>Empowering Youth, Building Futures and Trailblazing New Horizons</p>
                </div>
                <div className="mb-5 md:mb-0">
                    <h3 className="text-xl mb-3">Quick Links</h3>
                    <ul>
                        <li className="mb-2"><a href="/who-we-are" className="text-white hover:text-gray-300">About Us</a></li>
                        <li className="mb-2"><a href="/programs" className="text-white hover:text-gray-300">Programs</a></li>
                        <li className="mb-2"><a href="/impact" className="text-white hover:text-gray-300">Resources</a></li>
                        <li className="mb-2"><a href="/media" className="text-white hover:text-gray-300">Galleries</a></li>
                        <li className="mb-2"><a href="/call-for-action" className="text-white hover:text-gray-300">Take Action</a></li>
                        <li className="mb-2"><a href="/contact-us" className="text-white hover:text-gray-300">Contact Us</a></li>
                    </ul>
                </div>
                <div className="mb-5 md:mb-0">
                    <h3 className="text-xl mb-3">Follow Us</h3>
                    <a href="https://www.linkedin.com/company/south-sudan-youth-leadership-scholars-network-program/?viewAsMember=true" className="block mb-2 text-white hover:text-gray-300">LinkedIn</a>
                    <a href="https://www.facebook.com/people/South-Sudan-Youth-Leadership-Scholars-Network-Program-SSYLNET/100092027318021/" className="block mb-2 text-white hover:text-gray-300">Facebook</a>
                    <a href="https://twitter.com/i/flow/login?redirect_after_login=%2Fssylnet" className="block mb-2 text-white hover:text-gray-300">Twitter</a>
                </div>
                <div>
                    <h3 className="text-xl mb-3">Contact Us</h3>
                    <p>P.O. Box 29, Juba, South Sudan, Central Equatoria State, Internally Displaced Camp_Poc3</p>
                    <p>+211 929 415 361, +250 792 577 523</p>
                    <p>southsudanyouthnetwork.scholars<br />@gmail.com</p>
                </div>
            </footer>
            <p className="text-white text-center py-4">©️ SSYLNET Organization 2024 | All rights reserved: Website Designed by <span style={{ color: "darkred" }}>Joshua Alana</span></p>
        </>
    );
}

export default Home;
