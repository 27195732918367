import React from 'react';
import logo from "../images/logo.png";

function Belief() {
    return (
        <>
            <div className="max-w-7xl mx-auto p-5 flex flex-col gap-5 bg-black rounded-lg shadow-lg text-center items-center">
                <h1 className="text-center text-red-700 my-5 text-4xl font-bold">Our Belief System</h1>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 w-full">
                    {[
                        "We believe in the potential of every young person to make a difference.",
                        "We advocate for fairness and inclusivity for all individuals.",
                        "Integrity is fundamental to everything we do.",
                        "Collaboration and partnership are essential for impactful outcomes.",
                        "Innovation and creativity drive our efforts to address challenges.",
                        "Respect for diversity and dignity guides our interactions.",
                        "We take responsibility for our actions and decisions.",
                        "Continuous learning and growth are key to our success.",
                        "Sustainability is central to our mission.",
                        "Accountability ensures transparency and trust in our organization."
                    ].map((text, index) => (
                        <div key={index} className="bg-white p-5 rounded-lg shadow-md hover:shadow-lg transition-transform transform hover:-translate-y-1">
                            <p className="text-xl text-black">{text}</p>
                        </div>
                    ))}
                </div>
            </div>
            <footer className="bg-red-700 text-white p-10 flex flex-col md:flex-row justify-around items-center mt-10">
                <div className="mb-5 md:mb-0 text-center md:text-left">
                    <img src={logo} alt="Company Logo" className="w-32 mb-3 mx-auto md:mx-0 rounded bg-white p-1" />
                    <p>Empowering Youth, Building Futures and Trailblazing New Horizons</p>
                </div>
                <div className="mb-5 md:mb-0">
                    <h3 className="text-xl mb-3">Quick Links</h3>
                    <ul>
                        <li className="mb-2"><a href="/who-we-are" className="text-white hover:text-gray-300">About Us</a></li>
                        <li className="mb-2"><a href="/programs" className="text-white hover:text-gray-300">Programs</a></li>
                        <li className="mb-2"><a href="/impact" className="text-white hover:text-gray-300">Resources</a></li>
                        <li className="mb-2"><a href="/media" className="text-white hover:text-gray-300">Galleries</a></li>
                        <li className="mb-2"><a href="/call-for-action" className="text-white hover:text-gray-300">Take Action</a></li>
                        <li className="mb-2"><a href="/contact-us" className="text-white hover:text-gray-300">Contact Us</a></li>
                    </ul>
                </div>
                <div className="mb-5 md:mb-0">
                    <h3 className="text-xl mb-3">Follow Us</h3>
                    <a href="https://www.linkedin.com/company/south-sudan-youth-leadership-scholars-network-program/?viewAsMember=true" className="block mb-2 text-white hover:text-gray-300">LinkedIn</a>
                    <a href="https://www.facebook.com/people/South-Sudan-Youth-Leadership-Scholars-Network-Program-SSYLNET/100092027318021/" className="block mb-2 text-white hover:text-gray-300">Facebook</a>
                    <a href="https://twitter.com/i/flow/login?redirect_after_login=%2Fssylnet" className="block mb-2 text-white hover:text-gray-300">Twitter</a>
                </div>
                <div>
                    <h3 className="text-xl mb-3">Contact Us</h3>
                    <p>P.O. Box 29, Juba, South Sudan, Central Equatoria State, Internally Displaced Camp_Poc3</p>
                    <p>+211 929 415 361, +250 792 577 523</p>
                    <p>southsudanyouthnetwork.scholars<br />@gmail.com</p>
                </div>
            </footer>
            <p className="text-white text-center py-4">©️ SSYLNET Organization 2024 | All rights reserved: Website Designed by <span style={{ color: "darkred" }}>Joshua Alana</span></p>
        </>
    );
}

export default Belief;
