import React from 'react';
import logo from "../images/logo.png";

// Import your gallery images
import gal5 from '../images/gal5.jpg';
import gal6 from '../images/gal6.jpg';
import gal7 from '../images/gal7.jpg';
import gal8 from '../images/gal8.jpg';
import gal9 from '../images/gal9.jpg';
import gal10 from '../images/gal10.jpg';
import gal11 from '../images/gal11.jpg';
import gal12 from '../images/gal12.jpg';
import gal14 from '../images/gal14.jpg';
import gal15 from '../images/gal15.jpg';
import gal16 from '../images/gal16.jpg';
import gal17 from '../images/gal17.jpg';
import gal18 from '../images/gal18.jpg';
import gal19 from '../images/gal19.jpg';
import gal20 from '../images/gal20.jpg';
import gal21 from '../images/gal21.jpg';
import gal22 from '../images/gal22.jpg';
import gal23 from '../images/gal23.jpg';
import gal25 from '../images/gal25.jpg';
import gal27 from '../images/gal27.jpg';
import gal28 from '../images/gal28.jpg';
import gal30 from '../images/gal30.jpg';
import gal31 from '../images/gal31.jpg';

function Galleries() {
    return (
        <>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 p-4">
                {[gal5, gal8, gal9, gal10, gal14, gal15, gal16, gal17, gal18, gal19, gal6, gal7, gal20, gal21, gal22, gal23, gal11, gal25, gal12, gal27, gal28, gal30, gal31].map((imgSrc, index) => (
                    <img key={index} src={imgSrc} alt={`Gallery ${index + 1}`} className="w-full h-48 object-cover rounded-lg shadow-md" />
                ))}
                <a
                    href="https://drive.google.com/drive/folders/1W6mzXfxN40xTVW1BEIG3IBqowTMcK8Pv?usp=sharing"
                    target='_blank'
                    rel='noopener noreferrer'
                    className="w-full h-48 flex items-center justify-center bg-red-700 text-white font-bold rounded-lg shadow-md hover:bg-red-600 transition-colors duration-300"
                >
                    See More
                </a>
            </div>
            <footer className="bg-darkred-700 text-white py-10 px-5 flex flex-col md:flex-row justify-around items-center mt-10">
                <div className="mb-5 md:mb-0 text-center md:text-left">
                    <img src={logo} alt="Company Logo" className="w-32 mb-3 mx-auto md:mx-0 rounded bg-white p-1" />
                    <p>Empowering Youth, Building Futures and Trailblazing New Horizons</p>
                </div>
                <div className="mb-5 md:mb-0">
                    <h3 className="text-xl mb-3">Quick Links</h3>
                    <ul>
                        <li className="mb-2"><a href="/who-we-are" className="text-white hover:text-gray-300">About Us</a></li>
                        <li className="mb-2"><a href="/programs" className="text-white hover:text-gray-300">Programs</a></li>
                        <li className="mb-2"><a href="/impact" className="text-white hover:text-gray-300">Resources</a></li>
                        <li className="mb-2"><a href="/media" className="text-white hover:text-gray-300">Galleries</a></li>
                        <li className="mb-2"><a href="/call-for-action" className="text-white hover:text-gray-300">Take Action</a></li>
                        <li className="mb-2"><a href="/contact-us" className="text-white hover:text-gray-300">Contact Us</a></li>
                    </ul>
                </div>
                <div className="mb-5 md:mb-0">
                    <h3 className="text-xl mb-3">Follow Us</h3>
                    <a href="https://www.linkedin.com/company/south-sudan-youth-leadership-scholars-network-program/?viewAsMember=true" className="block mb-2 text-white hover:text-gray-300">LinkedIn</a>
                    <a href="https://www.facebook.com/people/South-Sudan-Youth-Leadership-Scholars-Network-Program-SSYLNET/100092027318021/" className="block mb-2 text-white hover:text-gray-300">Facebook</a>
                    <a href="https://twitter.com/i/flow/login?redirect_after_login=%2Fssylnet" className="block mb-2 text-white hover:text-gray-300">Twitter</a>
                </div>
                <div>
                    <h3 className="text-xl mb-3">Contact Us</h3>
                    <p>P.O. Box 29, Juba, South Sudan, Central Equatoria State, Internally Displaced Camp_Poc3</p>
                    <p>+211 929 415 361, +250 792 577 523</p>
                    <p>southsudanyouthnetwork.scholars<br />@gmail.com</p>
                </div>
            </footer>
            <p className="text-white text-center py-4">©️ SSYLNET Organization 2024 |All rights reserved: Website Designed by <span style={{ color: "darkred" }}>Joshua Alana</span></p>
        </>
    );
}

export default Galleries;
