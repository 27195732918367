import React, { useState } from 'react';
import founder from "../images/founder.jpeg";
import logo from "../images/logo.png";

function Story() {
    const [isExpanded, setExpanded] = useState(false);

    const toggleExpanded = () => {
        setExpanded(!isExpanded);
    };

    return (
        <>
            <div className="max-w-7xl mx-auto p-5 flex flex-col gap-5 bg-black rounded-lg shadow-lg text-center items-center">
                <div className="flex flex-col md:flex-row items-center md:items-start gap-5 w-full">
                    <div className="text-center md:text-left">
                        <img src={founder} alt="Our Founder" className="w-80 h-80 md:w-96 md:h-auto rounded-lg shadow-md mx-auto md:mx-0" />
                        <p className="mt-3 text-red-700 font-semibold">Latjor Wuon Lat Dak</p>
                        <p className="text-white">CEO & Executive Director</p>
                        <p className="text-white">SSYLNET Organization</p>
                    </div>
                    <div className="text-left">
                        <h1 className="text-red-700 text-3xl font-bold mb-3 text-center md:text-left">Our Story of Foundation: Resilience and Education</h1>
                        <p className="text-white mb-3">
                            <span className="text-red-700 text-4xl">❝</span>From the impoverished village of Nhail-Diu in war-torn South Sudan to the bustling halls of the African Leadership University, my journey embodies the resilience and transformative power of education. Born and growing up amidst the chaos of war conflict, I confronted a pivotal choice: remain in a land with scarce educational opportunities or seek a brighter future elsewhere.
                            Driven by a fervent desire for knowledge at a young age, I embarked on a challenging path to education.
                        </p>
                        <p className="text-white mb-3">
                            Enduring long hours footing from a remote village to an under-resourced school at the boma level where the absence of basic amenities like blackboards, chalkboards, classrooms, pens, and books failed, and just studied under trees with makeshift tools using the earth ground as our canvas, using charcoal to write and draw down our thoughts. From there, I discovered the unwavering spirit of learning.
                        </p>
                        <p className="text-white mb-3">
                            After the country gained independence, my path took an unexpected turn as I found myself in an IDP camp for a decade due to a political tribal war. Surrounded by the harsh realities of educational deprivation, I persevered, driven by a deep-seated passion for learning and teaching. Determined to make a difference, I volunteered as a teacher, even undergoing training by humanitarian organizations to enhance my skills as a volunteer teacher in crisis contexts at IDP Camps.
                        </p>
                        <p className="text-white mb-3">
                            In the camp, I made a conscious choice to remain resilient. Surrounded by peers from diverse backgrounds, I discovered a shared humanity that transcended tribal divides, challenging the prejudices of my upbringing and igniting a sense of national unity. This journey of endurance and devotion to education culminated in a transformative opportunity that led me to be awarded the "Mastercard Foundation scholarship" to the African Leadership University in Rwanda.
                        </p>
                        <p className="text-white mb-3">
                            Inspired by my passion and the mission I had witnessed and experienced at ALU, I founded the SSYLNET Organization with a mission to provide equitable education and transformative opportunities to the youth of South Sudan, breaking the cycle of poverty and conflict.
                        </p>
                        <p className="text-white mb-3">
                            My journey led me to participate in international summits, conferences, fellowships, and programs, building a full passion as a grassroots change agent advocating for SDG4, refugees & IDPs and peace while leveraging the power of storytelling to inspire policy change. I firmly believe in the transformative power of education to shape a brighter future for all, regardless of the circumstances. Join us in our mission to champion education in South Sudan, and together, we can make a lasting impact and build a brighter future for generations to come<span className="text-red-700 text-4xl">❞</span>.
                        </p>
                    </div>
                </div>
            </div>
            <footer className="bg-darkred-700 text-white py-10 px-5 flex flex-col md:flex-row justify-around items-center mt-10">
                <div className="mb-5 md:mb-0 text-center md:text-left">
                    <img src={logo} alt="Company Logo" className="w-32 mb-3 mx-auto md:mx-0 rounded bg-white p-1" />
                    <p>Empowering Youth, Building Futures and Trailblazing New Horizons</p>
                </div>
                <div className="mb-5 md:mb-0">
                    <h3 className="text-xl mb-3">Quick Links</h3>
                    <ul>
                        <li className="mb-2"><a href="/who-we-are" className="text-white hover:text-gray-300">About Us</a></li>
                        <li className="mb-2"><a href="/programs" className="text-white hover:text-gray-300">Programs</a></li>
                        <li className="mb-2"><a href="/impact" className="text-white hover:text-gray-300">Resources</a></li>
                        <li className="mb-2"><a href="/media" className="text-white hover:text-gray-300">Galleries</a></li>
                        <li className="mb-2"><a href="/call-for-action" className="text-white hover:text-gray-300">Take Action</a></li>
                        <li className="mb-2"><a href="/contact-us" className="text-white hover:text-gray-300">Contact Us</a></li>
                    </ul>
                </div>
                <div className="mb-5 md:mb-0">
                    <h3 className="text-xl mb-3">Follow Us</h3>
                    <a href="https://www.linkedin.com/company/south-sudan-youth-leadership-scholars-network-program/?viewAsMember=true" className="block mb-2 text-white hover:text-gray-300">LinkedIn</a>
                    <a href="https://www.facebook.com/people/South-Sudan-Youth-Leadership-Scholars-Network-Program-SSYLNET/100092027318021/" className="block mb-2 text-white hover:text-gray-300">Facebook</a>
                    <a href="https://twitter.com/i/flow/login?redirect_after_login=%2Fssylnet" className="block mb-2 text-white hover:text-gray-300">Twitter</a>
                </div>
                <div>
                    <h3 className="text-xl mb-3">Contact Us</h3>
                    <p>P.O. Box 29, Juba, South Sudan, Central Equatoria State, Internally Displaced Camp_Poc3</p>
                    <p>+211 929 415 361, +250 792 577 523</p>
                    <p>southsudanyouthnetwork.scholars<br />@gmail.com</p>
                </div>
            </footer>
            <p className="text-white text-center py-4">©️ SSYLNET Organization 2024 | All rights reserved: Website Designed by <span style={{ color: "darkred" }}>Joshua Alana</span></p>
        </>
    );
}

export default Story;
