import React from 'react';
import strategy from "../images/strategy.jpeg";
import logo from "../images/logo.png";

function StrategicFrameworks() {
    return (
        <>
            <div className="max-w-7xl mx-auto p-5 flex flex-col gap-5 bg-black rounded-lg shadow-lg text-center items-center">
                <h1 className="text-center text-red-700 my-5 text-4xl font-bold">Strategic Framework</h1>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 w-full">
                    <div className="bg-white p-5 rounded-lg shadow-md hover:shadow-lg transition-transform transform hover:-translate-y-1">
                        <h2 className="text-2xl text-red-700 mb-3">Strategic Partnerships</h2>
                        <p className="text-lg text-black text-left">We aim to cultivate strategic partnerships with local and international organizations, government agencies, and community leaders. By leveraging collective resources and expertise, we amplify our impact and reach.</p>
                    </div>
                    <div className="bg-white p-5 rounded-lg shadow-md hover:shadow-lg transition-transform transform hover:-translate-y-1">
                        <h2 className="text-2xl text-red-700 mb-3">Resource Mobilization</h2>
                        <p className="text-lg text-black text-left">We employ diverse resource mobilization strategies to secure funding and support for our programs. This includes grant writing, fundraising events, corporate partnerships, and individual donations.</p>
                    </div>
                    <div className="bg-white p-5 rounded-lg shadow-md hover:shadow-lg transition-transform transform hover:-translate-y-1">
                        <h2 className="text-2xl text-red-700 mb-3">Capacity Building</h2>
                        <p className="text-lg text-black text-left">We invest in the capacity building of our staff, volunteers, and beneficiaries to enhance program effectiveness and sustainability. This includes training, mentorship, and professional development opportunities.</p>
                    </div>
                    <div className="bg-white p-5 rounded-lg shadow-md hover:shadow-lg transition-transform transform hover:-translate-y-1">
                        <h2 className="text-2xl text-red-700 mb-3">Advocacy and Policy Influence</h2>
                        <p className="text-lg text-black text-left">We engage in advocacy efforts to influence policy decisions and promote systemic change. By advocating for policies that prioritize education, youth empowerment, and peacebuilding, we create an enabling environment for our work.</p>
                    </div>
                    <div className="bg-white p-5 rounded-lg shadow-md hover:shadow-lg transition-transform transform hover:-translate-y-1">
                        <h2 className="text-2xl text-red-700 mb-3">Monitoring and Evaluation</h2>
                        <p className="text-lg text-black text-left">We aim to implement robust monitoring and evaluation systems to assess the impact of our programs and inform decision-making. By collecting and analyzing data on key performance indicators, we continuously improve program quality and outcomes.</p>
                    </div>
                </div>
            </div>
            <footer className="bg-darkred-700 text-white py-10 px-5 flex flex-col md:flex-row justify-around items-center mt-10">
                <div className="mb-5 md:mb-0 text-center md:text-left">
                    <img src={logo} alt="Company Logo" className="w-32 mb-3 mx-auto md:mx-0 rounded bg-white p-1" />
                    <p>Empowering Youth, Building Futures and Trailblazing New Horizons</p>
                </div>
                <div className="mb-5 md:mb-0">
                    <h3 className="text-xl mb-3">Quick Links</h3>
                    <ul>
                        <li className="mb-2"><a href="/who-we-are" className="text-white hover:text-gray-300">About Us</a></li>
                        <li className="mb-2"><a href="/programs" className="text-white hover:text-gray-300">Programs</a></li>
                        <li className="mb-2"><a href="/impact" className="text-white hover:text-gray-300">Resources</a></li>
                        <li className="mb-2"><a href="/media" className="text-white hover:text-gray-300">Galleries</a></li>
                        <li className="mb-2"><a href="/call-for-action" className="text-white hover:text-gray-300">Take Action</a></li>
                        <li className="mb-2"><a href="/contact-us" className="text-white hover:text-gray-300">Contact Us</a></li>
                    </ul>
                </div>
                <div className="mb-5 md:mb-0">
                    <h3 className="text-xl mb-3">Follow Us</h3>
                    <a href="https://www.linkedin.com/company/south-sudan-youth-leadership-scholars-network-program/?viewAsMember=true" className="block mb-2 text-white hover:text-gray-300">LinkedIn</a>
                    <a href="https://www.facebook.com/people/South-Sudan-Youth-Leadership-Scholars-Network-Program-SSYLNET/100092027318021/" className="block mb-2 text-white hover:text-gray-300">Facebook</a>
                    <a href="https://twitter.com/i/flow/login?redirect_after_login=%2Fssylnet" className="block mb-2 text-white hover:text-gray-300">Twitter</a>
                </div>
                <div>
                    <h3 className="text-xl mb-3">Contact Us</h3>
                    <p>P.O. Box 29, Juba, South Sudan, Central Equatoria State, Internally Displaced Camp_Poc3</p>
                    <p>+211 929 415 361, +250 792 577 523</p>
                    <p>southsudanyouthnetwork.scholars<br />@gmail.com</p>
                </div>
            </footer>
            <p className="text-white text-center py-4">©️ SSYLNET Organization 2024 | All rights reserved: Website Designed by <span style={{ color: "darkred" }}>Joshua Alana</span></p>
        </>
    );
}

export default StrategicFrameworks;
