import React, { useState } from 'react';
import method from "../images/featured1.jpg";
import logo from "../images/logo.png";

function Methods() {
    const [isExpanded, setExpanded] = useState(false);

    const toggleExpanded = () => {
        setExpanded(!isExpanded);
    };

    return (
        <>
            <div className="max-w-7xl mx-auto p-5 flex flex-col gap-5 bg-black rounded-lg shadow-lg text-center items-center">
                <h1 className="text-center text-red-700 my-5 text-4xl font-bold">Our Methodology</h1>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 w-full">
                    <div className="bg-white p-5 rounded-lg shadow-md hover:shadow-lg transition-transform transform hover:-translate-y-1">
                        <h2 className="text-2xl text-red-700 mb-3">Community-Centric Approach</h2>
                        <p className="text-lg text-left text-black">Our approach is rooted in engaging directly with communities to understand their unique needs and challenges. By involving community members in the design and implementation of our programs, we ensure relevance and effectiveness.</p>
                    </div>
                    <div className="bg-white p-5 rounded-lg shadow-md hover:shadow-lg transition-transform transform hover:-translate-y-1">
                        <h2 className="text-2xl text-red-700 mb-3">Holistic Program Integration</h2>
                        <p className="text-lg text-black text-left ">We integrate multiple program components, such as education, leadership development, entrepreneurship, and peacebuilding, into holistic interventions. This comprehensive approach addresses the multifaceted needs of underprivileged youth and promotes sustainable development.</p>
                    </div>
                    <div className="bg-white p-5 rounded-lg shadow-md hover:shadow-lg transition-transform transform hover:-translate-y-1">
                        <h2 className="text-2xl text-red-700 mb-3">Participatory Decision-Making</h2>
                        <p className="text-lg text-black text-left ">We prioritize participatory decision-making processes, involving beneficiaries in program planning, implementation, and evaluation. By empowering youth to take ownership of their development, we foster a sense of agency and accountability.</p>
                    </div>
                    <div className="bg-white p-5 rounded-lg shadow-md hover:shadow-lg transition-transform transform hover:-translate-y-1">
                        <h2 className="text-2xl text-red-700 mb-3">Innovative Solutions</h2>
                        <p className="text-lg text-black text-left ">We embrace innovation and creativity in designing solutions to complex challenges. By leveraging technology, social entrepreneurship, and best practices, we develop innovative programs that yield impactful results.</p>
                    </div>
                    <div className="bg-white p-5 rounded-lg shadow-md hover:shadow-lg transition-transform transform hover:-translate-y-1">
                        <h2 className="text-2xl text-red-700 mb-3">Culturally Sensitive Practices</h2>
                        <p className="text-lg text-black text-left ">We respect and honor local customs, traditions, and cultural norms in our approach. By incorporating culturally sensitive practices, we ensure that our programs are inclusive and respectful of diverse identities and backgrounds.</p>
                    </div>
                </div>
            </div>
            <footer className="bg-darkred-700 text-white p-10 flex flex-col md:flex-row justify-around items-center mt-10">
                <div className="mb-5 md:mb-0 text-center md:text-left">
                    <img src={logo} alt="Company Logo" className="w-32 mb-3 mx-auto md:mx-0 rounded bg-white p-1" />
                    <p>Empowering Youth, Building Futures and Trailblazing New Horizons</p>
                </div>
                <div className="mb-5 md:mb-0">
                    <h3 className="text-xl mb-3">Quick Links</h3>
                    <ul>
                        <li className="mb-2"><a href="/who-we-are" className="text-white hover:text-gray-300">About Us</a></li>
                        <li className="mb-2"><a href="/programs" className="text-white hover:text-gray-300">Programs</a></li>
                        <li className="mb-2"><a href="/impact" className="text-white hover:text-gray-300">Resources</a></li>
                        <li className="mb-2"><a href="/media" className="text-white hover:text-gray-300">Galleries</a></li>
                        <li className="mb-2"><a href="/call-for-action" className="text-white hover:text-gray-300">Take Action</a></li>
                        <li className="mb-2"><a href="/contact-us" className="text-white hover:text-gray-300">Contact Us</a></li>
                    </ul>
                </div>
                <div className="mb-5 md:mb-0">
                    <h3 className="text-xl mb-3">Follow Us</h3>
                    <a href="https://www.linkedin.com/company/south-sudan-youth-leadership-scholars-network-program/?viewAsMember=true" className="block mb-2 text-white hover:text-gray-300">LinkedIn</a>
                    <a href="https://www.facebook.com/people/South-Sudan-Youth-Leadership-Scholars-Network-Program-SSYLNET/100092027318021/" className="block mb-2 text-white hover:text-gray-300">Facebook</a>
                    <a href="https://twitter.com/i/flow/login?redirect_after_login=%2Fssylnet" className="block mb-2 text-white hover:text-gray-300">Twitter</a>
                </div>
                <div>
                    <h3 className="text-xl mb-3">Contact Us</h3>
                    <p>P.O. Box 29, Juba, South Sudan, Central Equatoria State, Internally Displaced Camp_Poc3</p>
                    <p>+211 929 415 361, +250 792 577 523</p>
                    <p>southsudanyouthnetwork.scholars<br />@gmail.com</p>
                </div>
            </footer>
            <p className="text-white text-center py-4">©️ SSYLNET Organization 2024 | All rights reserved: Website Designed by <span style={{ color: "darkred" }}>Joshua Alana</span></p>
        </>
    );
}

export default Methods;
