import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import logo from "../images/logo.png";
import part1 from "../images/part1.jpeg";
import part2 from "../images/part2.jpeg";

function Partners() {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: ''
    });
    const [responseMessage, setResponseMessage] = useState('');

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_m62mnn8', 'template_1iy8uth', e.target, 'bt_mlgGFtmB-yxJO9')
            .then((result) => {
                console.log(result.text);
                setResponseMessage('Thank you for reaching out to us. We will get back to you as soon as possible.');
                setFormData({ name: '', email: '', message: '' });
            }, (error) => {
                console.log(error.text);
                setResponseMessage('Failed to send the message. Please try again.');
            });
    };

    return (
        <>
            <div className="bg-black py-16 px-8">
                <h2 className="text-4xl text-red-700 text-center font-bold mb-8">Our Partners</h2>
                <p className="text-lg text-white-700 text-center mb-12">At SSYLNET, we believe in the power of collaboration to drive meaningful change. Our partnerships with like-minded organizations strengthen our efforts to provide equitable education and transformative opportunities to the youth of South Sudan. We are proud to partner with the following organizations:</p>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-12">
                    <div className="bg-black rounded-lg shadow-md p-6 text-center">
                        <img src={part1} alt="TEDI Africa Logo" className="w-24 mx-auto mb-4" />
                        <h3 className="text-2xl text-red-700 font-semibold mb-4">TEDI Africa</h3>
                        <p className="text-white-600 mb-4">TEDI Africa is a registered non-profit, non-governmental, multi-sectoral development social enterprise operating in South Sudan and Kenya. TEDI Africa focuses on empowering women and underprivileged youth through dynamic vocational education that integrates system thinking, social innovation techniques, and human-centered design thinking methodology. Their approach aims to solve socio-economic challenges, fostering entrepreneurial spirit and sustainable development. We are honored to collaborate with TEDI Africa in our mission to support youth entrepreneurship and education in South Sudan.</p>
                        <a href="https://tediafrica.org/" target="_blank" rel="noopener noreferrer" className="inline-block py-2 px-4 bg-red-700 text-white-700 rounded-lg hover:bg-red-700 transition-colors">Visit TEDI Africa</a>
                    </div>
                    <div className="bg-black rounded-lg shadow-md p-6 text-center">
                        <img src={part2} alt="Bangtigow Foundation Logo" className="w-24 mx-auto mb-4" />
                        <h3 className="text-2xl text-red-700 font-semibold mb-4">Bangtigow Foundation</h3>
                        <p className="text-white-600 mb-4">Bangtigow Foundation is a registered 501(c)(3) nonprofit organization based in Gambella, Ethiopia. The foundation is dedicated to opening and providing educational access and opportunities to underprivileged and marginalized young people in South Sudan. Their commitment to addressing educational inequalities aligns with our vision of empowering youth through education. Together, we work to create pathways for academic success and personal development for the youth of South Sudan.</p>
                        <a href="https://www.linkedin.com/company/bangtigow/" target="_blank" rel="noopener noreferrer" className="inline-block py-2 px-4 bg-red-700 text-white rounded-lg hover:bg-red-700 transition-colors">Visit Bangtigow Foundation</a>
                    </div>
                </div>
            </div>
            <div className="bg-black py-16 px-8">
                <div className="max-w-lg mx-auto">
                    <h1 className="text-4xl text-red-700 font-bold text-center mb-6">Partner with Us</h1>
                    <p className="text-lg text-white-700 text-center mb-8">Be part of our mission to make a difference.</p>
                    <form onSubmit={handleSubmit} className="space-y-6">
                        <div>
                            <label htmlFor="name" className="block text-white-700 font-medium mb-2">Name:</label>
                            <input
                                type="text"
                                id="name"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                required
                                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 bg-black text-white shadow-sm"
                            />
                        </div>
                        <div>
                            <label htmlFor="email" className="block text-white-700 font-medium mb-2">Email:</label>
                            <input
                                type="email"
                                id="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                required
                                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 bg-black text-white shadow-sm"
                            />
                        </div>
                        <div>
                            <label htmlFor="message" className="block text-white-700 font-medium mb-2">Message:</label>
                            <textarea
                                id="message"
                                name="message"
                                rows="4"
                                value={formData.message}
                                onChange={handleChange}
                                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 bg-black text-white shadow-sm"
                            ></textarea>
                        </div>
                        <button
                            type="submit"
                            className="w-full bg-red-500 text-white font-bold py-2 px-4 rounded-lg hover:bg-red-600 transition-colors duration-300 shadow-md"
                        >
                            Submit
                        </button>
                    </form>
                    {responseMessage && <p className="mt-4 text-center text-green-500">{responseMessage}</p>}
                </div>
            </div>
            <footer className="bg-darkred-700 text-white py-10 px-5 flex flex-col md:flex-row justify-around items-center mt-10">
                <div className="mb-5 md:mb-0 text-center md:text-left">
                    <img src={logo} alt="Company Logo" className="w-32 mb-3 mx-auto md:mx-0 rounded bg-white p-1" />
                    <p>Empowering Youth, Building Futures and Trailblazing New Horizons</p>
                </div>
                <div className="mb-5 md:mb-0">
                    <h3 className="text-xl mb-3">Quick Links</h3>
                    <ul>
                        <li className="mb-2"><a href="/who-we-are" className="text-white hover:text-gray-300">About Us</a></li>
                        <li className="mb-2"><a href="/programs" className="text-white hover:text-gray-300">Programs</a></li>
                        <li className="mb-2"><a href="/impact" className="text-white hover:text-gray-300">Resources</a></li>
                        <li className="mb-2"><a href="/media" className="text-white hover:text-gray-300">Galleries</a></li>
                        <li className="mb-2"><a href="/call-for-action" className="text-white hover:text-gray-300">Take Action</a></li>
                        <li className="mb-2"><a href="/contact-us" className="text-white hover:text-gray-300">Contact Us</a></li>
                    </ul>
                </div>
                <div className="mb-5 md:mb-0">
                    <h3 className="text-xl mb-3">Follow Us</h3>
                    <a href="https://www.linkedin.com/company/south-sudan-youth-leadership-scholars-network-program/?viewAsMember=true" className="block mb-2 text-white hover:text-gray-300">LinkedIn</a>
                    <a href="https://www.facebook.com/people/South-Sudan-Youth-Leadership-Scholars-Network-Program-SSYLNET/100092027318021/" className="block mb-2 text-white hover:text-gray-300">Facebook</a>
                    <a href="https://twitter.com/i/flow/login?redirect_after_login=%2Fssylnet" className="block mb-2 text-white hover:text-gray-300">Twitter</a>
                </div>
                <div>
                    <h3 className="text-xl mb-3">Contact Us</h3>
                    <p>P.O. Box 29, Juba, South Sudan, Central Equatoria State, Internally Displaced Camp_Poc3</p>
                    <p>+211 929 415 361, +250 792 577 523</p>
                    <p>southsudanyouthnetwork.scholars<br />@gmail.com</p>
                </div>
            </footer>
            <p className="text-white text-center py-4">©️ SSYLNET Organization 2024 | All rights reserved: Website Designed by <span style={{ color: "darkred" }}>Joshua Alana</span></p>
        </>
    );
}

export default Partners;
